<template>
  <RouterView />
  <div class="community-single flex fd-c ggap-10">
    <!-- Head -->
    <div
      v-if="item"
      class="community-single__head box p-20 z3 flex pos-r"
      :class="[isMobile ? 'fd-cr ai-fe' : 'ggap-20']"
    >
      <!-- Delete -->
      <div v-if="isDelete" class="community-single__delete pos-a z2 flex fd-c ai-c jc-c ggap-10">
        <div v-if="isLoad" class="flex fd-c ai-c ggap-5">
          <BaseLoad class="rel sm" />
          <h3 class="title t-white">Идет удаление записи!</h3>
        </div>
        <template v-else>
          <h2 class="title t-white">Вы действительно хотите удалить?</h2>
          <div class="flex ai-c ggap-10">
            <button class="btn primary sm" @click="deleteAction">Удалить</button>
            <button class="btn cancel sm" @click="isDelete = false">Отмена</button>
          </div>
        </template>
      </div>

      <div class="flex ggap-10" :class="{ 'fd-c ai-c jc-fs w-100': isMobile }">
        <div class="cover ava-100 bordered" :class="[isMobile ? 'ava-200' : 'ava-100']">
          <img
            :src="item.image"
            :onerror="setImage"
            class="community-single__avatar pointer"
            v-if="item.image"
            @click.prevent="openMedia"
          />
          <img :src="require('@/assets/img/no-photo.jpg')" v-if="!item.image" />
        </div>

        <!-- Description -->
        <div class="flex fd-c jc-fs ggap-5" :class="{ 'ai-fs w-100': isMobile }">
          <div class="flex ai-fs jc-sb ggap-10">
            <h1 class="title">{{ item.title }}</h1>

            <div class="flex ai-c ggap-10">
              <!-- Subscribe -->
              <button
                v-if="!isAuthor"
                class="btn transparent-grey sm pl-10 pr-10"
                :disabled="isLoadSubscribe"
                v-tippy="isSubscribed ? 'Вы подписанны, нажмите чтобы отписаться.' : 'Нажмите чтобы подписаться.'"
                @click.prevent="subscribeAction"
              >
                <BaseLoad v-if="isLoadSubscribe" class="sm rel" />
                <BaseIcon v-else class="ic-20" :class="isSubscribed ? 'primary' : 'grey'" icon="check-circle" />
                {{ isSubscribed ? 'Отписаться' : 'Подписаться' }}
              </button>

              <!-- Menu -->
            </div>
          </div>

          <div v-if="item.tags && item.tags.length" class="flex fw ai-c ggap-5-10">
            <!-- <a v-for="tag of item.tags" :key="tag.id" :href="tag.id">#{{ tag.name }}</a> -->
            <span v-for="tag of item.tags" :key="tag.id" class="tag-item">{{ tag.name }}</span>
          </div>

          <BaseUsers v-if="users && users.length" :items="users" innerClass="mt-10" />

          <div class="community-single__desc" v-html="DOMPurify.sanitize(item.description)" />
        </div>
      </div>
      <!-- Avatar -->
      <BaseDotMenu v-if="!isDelete && isAuthor">
        <button class="btn transparent-grey" @click.prevent="shareAction">
          <BaseIcon class="ic-16 grey" icon="share" /> Поделиться
        </button>

        <template v-if="item.author.id === profile.id">
          <hr />
          <button class="btn transparent-grey" @click="$busEmit('edit', { url: 'community/Index.vue', id: item.id })">
            <BaseIcon class="ic-16 grey" icon="edit" /> Редактировать
          </button>
          <button class="btn transparent-grey" @click="isDelete = !isDelete">
            <BaseIcon class="ic-16 grey" icon="delete" /> Удалить
          </button>
        </template>
      </BaseDotMenu>
    </div>

    <!-- Toolbar -->
    <ToolbarVue class="community-single__toolbar" :isAuthor="isAuthor" @load="isLoad = $event" />

    <!-- Body -->
    <div class="community-single__body flex fd-c ggap-10 pos-r z1">
      <BaseLoad v-if="isLoad" class="grid jc-c z3 bg white box" />
      <template v-else>
        <template v-if="posts && posts.length">
          <ItemPost v-for="post of posts" :key="post.id" :item="post" :link="`${route.params.id}`" />
          <BaseLoad v-if="isLoadMore" class="rel sm" />
        </template>
        <div v-else class="p-20 ta-center box">
          <h2 class="title mb-5">Упс, тут пока что пусто!</h2>
          <small class="t-grey-ed">Видимо еще никто не написал ни одного поста</small>
        </div>
      </template>
    </div>
  </div>
</template>

<script setup>
import { useStore } from 'vuex'
import { useRoute } from 'vue-router'
import { computed, ref, onMounted, onUnmounted, watch } from 'vue'
import { BaseIcon, BaseLoad, BaseDotMenu, BaseUsers, ItemPost } from '@/components'
import ToolbarVue from './components/Toolbar.vue'
import { $busEmit, $busOn, copyTextToClipboard, socketConnect } from '@/plugins'
import { communities } from '@/api'
import DOMPurify from 'dompurify'

// Data
const store = useStore()
const route = useRoute()
const isDelete = ref(false)
const isCopied = ref(false)
const isLoad = ref(false)
const isLoadMore = ref(false)
const isLoadSubscribe = ref(false)
const item = ref(null)

// Computed
const posts = computed(() => store.getters['news/rows'])
const pages = computed(() => store.getters['news/pages'])
const profile = computed(() => store.getters.profile)
const isAuthor = computed(() => profile.value.id === item.value?.author?.id)
const isSubscribed = computed(() => item.value?.users.findIndex((el) => el.id === profile.value.id) !== -1)
const isMobile = computed(() => store.getters.isMobile)
const users = computed(() => item.value?.users.filter((el) => el.id !== item.value?.author?.id))

// Watch
watch(
  () => route.params.id,
  () => {
    getItem()
    wsConnect()
    $busOn('updateItem', () => getItem(false))
  }
)

// Created
getItem()
wsConnect()
$busOn('updateItem', () => getItem(false))

// Mounted
onMounted(() => {
  const el = document.querySelector('.default-main')
  el.addEventListener('scroll', async function () {
    if (el.scrollTop > el.scrollHeight - 2000) await getNextRows()
  })
})

// UnMounted
onUnmounted(() => {
  store.commit('news/CLEAR')
})

// Methods:item
async function getItem(isHasLoad = true) {
  if (route.params.id) {
    try {
      if (isHasLoad) isLoad.value = true
      const { data } = await communities.get(route.params.id)
      item.value = data.data
      if (isHasLoad) getRows()
    } catch (error) {
      console.log(error)
    }
  }
}
function setImage() {
  item.value.image = require('@/assets/img/no-photo.jpg')
}
function openMedia() {
  const arr = [
    {
      id: '123456',
      type: 'image',
      url: item.value.image
    }
  ]
  $busEmit('openMedia', { selected: '123456', arr })
}

// Methods:posts
async function getRows() {
  try {
    isLoad.value = true
    const params = {
      community: 1,
      communityId: route.params.id
    }
    if (localStorage.getItem('choco_news-filter')) {
      const filter = localStorage.getItem('choco_news-filter')
      params[filter] = 1
    }
    await store.dispatch('news/GET_ROWS', params)
  } catch (error) {
    console.log(error)
  } finally {
    isLoad.value = false
  }
}
async function getNextRows() {
  if (pages.value?.current_page < pages.value?.last_page && !isLoadMore.value) {
    try {
      isLoadMore.value = true
      const params = {
        community: 1,
        communityId: route.params.id,
        page: pages.value.current_page + 1
      }
      if (posts.value?.length) params.postId = posts.value[0].id
      if (localStorage.getItem('choco_news-filter')) {
        const filter = localStorage.getItem('choco_news-filter')
        params[filter] = 1
      }
      await store.dispatch('news/GET_ROWS', params)
    } catch (error) {
      console.log(error)
    } finally {
      isLoadMore.value = false
    }
  }
}
function subscribeAction() {
  isLoadSubscribe.value = true
  const link = window.location.href
  if (!isSubscribed.value) {
    communities.subscribe({ community_id: Number(route.params.id) }).then(async () => {
      await getItem(false)
      isLoadSubscribe.value = false
      $busEmit('setToast', {
        type: 'green',
        icon: 'check-circle',
        title: 'Спасибо!',
        message: `Вы успешно подписались на сообщество <a href="${link}">${item.value.title}</a>`
      })
    })
  } else {
    communities.unsubscribe(route.params.id).finally(async () => {
      await getItem(false)
      isLoadSubscribe.value = false
      $busEmit('setToast', {
        type: 'green',
        icon: 'check-circle',
        title: 'Жаль!',
        message: `Вы успешно отписались от сообщества <a href="${link}">${item.value.title}</a>`
      })
    })
  }
}
async function shareAction() {
  const link = `${window.location.origin}/communities/single/${item.value.id}`
  isCopied.value = await copyTextToClipboard(link)
  $busEmit('setToast', {
    type: 'green',
    icon: 'check-circle',
    message: `Ссылка на сообщество <a href="${link}">${item.value.title}</a> успешно скопированна!`
  })
}

// Methods:websocket
function wsConnect() {
  const nameEdit = `private-community.${route.params.id}`
  socketConnect.channel(nameEdit).listen('.CommunityUpdatedPrivateEvent', () => {
    getItem(false)
  })
}
</script>

<style lang="scss">
.community-single {
  &__delete {
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    background-color: var(--rgba-red-08);
    backdrop-filter: blur(5px);
    border-radius: var(--br);
  }

  &__avatar {
    position: sticky;
    top: 0;
    z-index: 1;

    &:hover {
      img {
        opacity: 0.5;
      }
    }
  }

  &__toolbar {
    position: sticky;
    top: 0;
    z-index: 2;
    box-shadow: 0 -30px 0 0 var(--bg);
  }

  &__body {
    min-height: 100px;
  }
}
@media screen and (max-width: 800px) {
  .community-single {
    &__head {
      display: flex;
    }
  }
}
</style>
