<template>
  <div class="toolbar box p-5 flex ai-c jc-sb ggap-5">
    <button class="btn" :class="!currentFilter ? 'primary' : 'transparent-grey'" @click="submitAction('')">
      Все посты
    </button>
    <!-- <button
      class="btn"
      :class="currentFilter === 'documents' ? 'primary' : 'transparent-grey'"
      @click="submitAction('documents')"
    >
      Файлы
    </button> -->
    <!-- <button
      class="btn"
      :class="currentFilter === 'links' ? 'primary' : 'transparent-grey'"
      @click="submitAction('links')"
    >
      Медиа
    </button> -->

    <div class="flex ai-c ggap-5 ml-auto">
      <button
        v-if="props.isAuthor"
        class="btn transparent-grey pl-10 pr-10"
        @click="$busEmit('add', 'post/Index.vue')"
        v-tippy="'Добавить пост'"
      >
        <BaseIcon class="ic-18 grey" icon="plus-circle" />
      </button>
      <button
        class="btn transparent-grey pl-10 pr-10 ml-auto"
        @click="submitAction(currentFilter, true)"
        v-tippy="'Есть новые посты, нажмите чтобы обновить список постов'"
        :disabled="!isHasUpdates"
      >
        <BaseIcon class="ic-20 secondary" icon="update" />
      </button>
    </div>
  </div>
</template>

<script setup>
import { useStore } from 'vuex'
import { useRoute } from 'vue-router'
import { defineEmits, defineProps, ref } from 'vue'
import { BaseIcon } from '@/components'
import { $busEmit, socketConnect } from '@/plugins'

// Emits
const emits = defineEmits(['load'])

// Props
const props = defineProps({
  isAuthor: {
    type: Boolean,
    default: false
  }
})

// Data
const store = useStore()
const route = useRoute()
const currentFilter = ref('')
const isHasUpdates = ref(false)
const currentId = route.params.id

// Created
wsConnect()
if (localStorage.getItem('choco_communities-filter')) {
  currentFilter.value = localStorage.getItem('choco_communities-filter')
}

// Methods
async function submitAction(filter, isUpdate = false) {
  if (currentFilter.value !== filter || isUpdate) {
    currentFilter.value = filter
    emits('load', true)
    const params = {
      community: 1,
      communityId: route.params.id
    }
    if (filter) {
      localStorage.setItem('choco_communities-filter', filter)
      params[filter] = 1
    } else {
      localStorage.removeItem('choco_communities-filter')
    }
    await store.dispatch('news/GET_ROWS', params)
    const scrlContent = document.querySelector('.default-main')
    scrlContent.scroll({ top: 0, left: 0, behavior: 'smooth' })
    emits('load', false)
    isHasUpdates.value = false
  }
}

// Methods:websocket
function wsConnect() {
  // if (!props.isAuthor) {
  const namePosts = `private-post.${currentId}`
  socketConnect.channel(namePosts).listen('.PostCreatedPrivateEvent', () => {
    isHasUpdates.value = true
  })
  // }
}
</script>

<style lang="scss" scoped>
.toolbar {
  height: 50px;
}
</style>
